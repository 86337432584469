<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="shops"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Список магазинов</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Добавить новый
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Название магазина"
                            placeholder="Название магазина"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="editedItem.preposition"
                            label="Предлог"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            v-model="editedItem.short_name"
                            label="Название со склонением"
                            placeholder="Название со склонением"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.epn_id"
                            label="ID оффера EPN"
                            placeholder="ID оффера EPN"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.admitad_id"
                            label="ID оффера AdmitAD"
                            placeholder="ID оффера AdmitAD"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="save"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              {{mdiPencil}}
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              {{mdiDelete}}
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import httpClient from '@/services/http.service'
import qs from 'querystring'
export default {
  name: 'Index',
  data(){
    return{
      dialog: false,
      mdiPencil, mdiDelete,
      headers: [
        {text: 'Название', value: 'name'},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      shops: [],
      editedIndex: -1,
      defaultItem:{
        name: '',
        epn_id: 0,
        admitad_id: 0,
        short_name: '',
        preposition: '',
      },
      editedItem: {
        name: '',
        epn_id: 0,
        admitad_id: 0,
        short_name: '',
        preposition: '',
      },
    }
  },
  computed:{
    formTitle() {
      return this.editedIndex === -1 ? 'Новый магазин' : 'Редактировать магазин'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    this.shops = this.$store.state.shops
  },
  methods:{
    editItem(item) {
      this.editedIndex = this.shops.indexOf(item)
      this.editedItem = { ...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.shops.indexOf(item)
      this.editedItem = { ...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.shops.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.shops[this.editedIndex], this.editedItem)
        httpClient.put(`shops/${this.editedItem.id}`, qs.stringify(this.editedItem)).then(()=>{
          this.$store.dispatch('getShops')
        })
      } else {
        httpClient.post('shops', qs.stringify(this.editedItem)).then(({data})=>{
          this.shops.push(data)
        })
      }
      this.close()
    },
  }
}
</script>

<style scoped>

</style>
